@import './../../variables.scss';

.home {
  overflow: hidden;

  .main_window {
    color: $white;
    margin-bottom: 0px;
    width: 100%;

    &:before {
      @include pseudo-abs();
      background-image: url('../../assets/images/Home.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center left;
      animation: backgroundImage 1.4s 1 ease-in-out;
    }

    &:after {
      @include pseudo-abs();
      background-color: rgba(0, 0, 0, 0.5);
      animation: fadeIn 1.4s 1 ease-in-out;
    }

    @keyframes backgroundImage {
      0% {
        transform: scale(1.3);
        // opacity: 0.5;
      }
      100% {
        transform: scale(1);
      }
    }

    .content_wrap {
      z-index: 1;
      min-height: calc(100vh - #{$mainnav_height});
      position: relative;
      @include flex-row();
      justify-content: flex-start;
      // min-height: 635px;

      .claim_box_wrap {
        position: absolute;
        left: 50%;
        bottom: -100px;
        width: 100%;
        transform: translate(-50%);
        max-width: 1000px;

        .claim_box_wrap_visible {
          background: #ffffff;
          box-shadow: 0px 14px 48px rgba(67, 101, 213, 0.18);
          border-radius: 10px;
          padding-top: 50px;
          padding-bottom: 30px;
          padding-left: 40px;
          @include flex-column();
          padding-right: 40px;

          p {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
            text-align: center;
            margin: 0;
            margin-bottom: 34px;
          }
        }
      }

      .detail_content {
        @include flex-column();
        align-items: flex-start;
        justify-content: space-around;
        // max-height: 406px;
        min-height: min(calc(100vh - #{$mainnav_height}), 406px);
        text-shadow: 0px 0px 5px #000;

        h1,
        h2 {
          max-width: 575px;
          color: $white;
          font-size: 40px;
          line-height: 49px;
        }

        h2 {
          max-width: 624px;
          font-size: 20px;
          line-height: 25px;
        }
      }

      .buttons_wrap {
        margin-top: 25px;
        width: 500px;

        button {
          &:first-child {
            margin-right: 20px;
          }
        }
      }
    }
  }

  .interoperability_wrap {
    margin-top: 80px;

    .content_wrap {
      @include flex-column();

      h1 {
        max-width: 827px;
      }

      .detail_wrap {
        @include flex-row();
        align-items: flex-start;
        width: 100%;

        .code_box {
          box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.25);
          max-width: 600px;
          padding: 19px 38px;
          margin-right: 30px;
          width: 100%;
          overflow: hidden;
        }

        .details_lists {
          @include flex-column();
          max-width: 200px;
          width: 100%;
          margin-top: 30px;

          .list_item {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 22px;
            width: 100%;
            border-bottom: 1px solid $grey-lite;
            padding: 10px 0;
            color: $grey-dark;
            cursor: pointer;
          }
        }
      }
    }
  }

  .mined_tokens {
    // min-height: min(100vh, 1000px);
    margin-top: 240px;
    padding-bottom: 160px;
    @include flex-row();
    width: 100%;

    .content_wrap {
      @include flex-row();
      margin-left: 0;
      transform: none;
      position: relative;

      &:before {
        @include pseudo-abs();
        width: 363px;
        height: 363px;
        background: #d5ffcb;
        border-radius: 50%;
        top: 0;
        left: 43px;
        animation: zoomIn 7.5s infinite alternate;
      }

      &:after {
        @include pseudo-abs();
        width: 206px;
        height: 206px;
        background: #e4f9fb;
        border-radius: 50%;
        top: auto;
        left: auto;
        bottom: -70px;
        right: 25%;
        z-index: -1;
        // animation: zoomIn 4.5s infinite alternate;
      }

      .mined_box {
        box-shadow: 0px 15px 25px rgba(168, 178, 228, 0.17);
        border-radius: 20px;
        border-left: 4px solid #9faffc;
        border-right: 4px solid #9faffc;
        max-width: 1000px;
        padding: 20px 30px;
        @include flex-column();
        text-align: center;
        padding-top: 0;
        padding-bottom: 70px;
        position: relative;
        background-color: #fff;

        h1 {
          margin-top: -30px;
          margin-bottom: 0;
        }
        .description {
          max-width: 873px;
          font-weight: 400;
          font-size: 28px;
          line-height: 40px;
        }
      }
    }
  }

  .basic_information {
    // min-height: min(100vh, 1000px);
    padding-top: 80px;

    .content_wrap {
      background-color: #fff;
      z-index: 1;
      position: relative;

      &.circle_orange {
        &:before {
          @include pseudo-abs();
          width: 500px;
          height: 500px;
          background: #ffd3b4;
          border-radius: 50%;
          top: auto;
          left: auto;
          bottom: -150px;
          right: 0;
          z-index: -1;
          animation: zoomIn 4.5s infinite alternate;
        }
      }

      &:after {
        @include pseudo-abs();
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: -1;
      }
    }

    .text_image_box {
      @include flex-row();
      box-shadow: 1px 5px 28px -4px rgba(0, 0, 0, 0.1);
      padding: 42px;
      align-items: flex-start;

      .details {
        width: 45%;

        h1 {
          margin-bottom: 15px;
        }

        p {
          font-weight: 400;
          font-size: 23px;
          line-height: 40px;
          max-width: 435px;
        }
      }

      .image {
        width: 65%;

        img {
          width: 100%;
          object-fit: cover;
          height: 100%;
          min-height: min(calc(100vh - 320px), 900px);
        }
      }
    }
  }

  .versatile_bridge_wrap {
    margin-top: 0px;
    padding-bottom: 40px;

    .plans_wrap {
      @include flex-column();
      position: relative;
      margin-top: 70px;

      &:before {
        @include pseudo-abs();
        width: 2px;
        background-color: #d7deff;
        height: calc(100% - 50px);
        left: 50%;
        transform: translate(-50%);
      }
    }
  }

  .global_community {
    background-color: #fafcff;
    padding-top: 75px;
    padding-bottom: 130px;

    .social_cards_wrap {
      margin-top: 50px;
      padding: 0 20px;
    }

    .content_wrap {
      @include flex-column();

      h1 {
        font-size: 40px;
        line-height: 49px;
      }

      h3 {
        width: 100%;
        max-width: 594px;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .home {
    overflow: hidden;

    .main_window {
      background-position-x: 51%;
      background-position: center;

      h1,
      h2 {
        max-width: 280px;
      }

      .content_wrap {
        min-height: 100vh;
        flex-wrap: wrap;

        .detail_content {
          min-height: auto;
          flex-wrap: wrap;
          width: 100%;
          align-items: flex-start;
          padding: 0 10px;
          padding-top: 40px;

          h1,
          h2 {
            max-width: 100%;
          }

          h1 {
            font-size: 26px;
            line-height: 36px;
          }

          .buttons_wrap {
            display: flex;
            flex-direction: column;
            gap: 15px;
            width: 100%;

            button {
              width: 100%;
              margin-right: 0 !important;
              max-width: 100%;
            }
            a {
              width: 100% !important;
            }
          }
        }

        .claim_box_wrap {
          max-width: calc(100% - 40px);
          bottom: -170px;

          .claim_box_wrap_visible {
            padding-top: 40px;
            padding-left: 30px;
            padding-right: 30px;

            p {
              font-size: 23px;
              margin-bottom: 25px;
            }
          }
        }
      }
    }

    .interoperability_wrap {
      margin-top: 0px;

      .content_wrap {
        .detail_wrap {
          @include flex-column();
          flex-direction: column-reverse;

          .code_box {
            margin-right: 0;
          }

          .details_lists {
            max-width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            margin-bottom: 25px;

            .list_item {
              max-width: calc(50% - 10px);
              margin: 0 5px;
              text-align: center;
            }
          }
        }
      }
    }

    .basic_information {
      padding-top: 40px;

      .content_wrap {
        &.circle_orange:before {
          width: 350px;
          height: 350px;
          bottom: -100px;
        }

        .text_image_box {
          padding: 15px;
          flex-direction: column;

          .details {
            width: 100%;
            order: 2;

            p {
              font-size: 14px;
              line-height: 20px;
            }

            button {
              width: 100%;
            }
          }

          .image {
            order: 1;
            margin-bottom: 15px;
            width: 100%;

            img {
              min-height: 200px;
            }
          }
        }
      }
    }

    .mined_tokens {
      margin-top: 120px;

      .content_wrap {
        .mined_box {
          .description {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }
    }

    .global_community {
      padding-bottom: 50px;
      padding-top: 30px;

      .social_cards_wrap {
        margin-top: 50px;
        @include flex-row();
        flex-wrap: wrap;
      }
    }
  }
}
