@import './../../variables.scss';

.hamburger {
  display: none;
  transform: scale(0.35);
  float: right;
  position: relative;
  padding-right: 17px;
  position: absolute;
  right: -20px;

  &.active li:nth-of-type(1) {
    top: 40%;
    transform: translateY(-50%) rotate(45deg);
  }
  &.active li:nth-of-type(2) {
    top: 40%;
    transform: translateY(-50%) rotate(-45deg);
  }
  &.active li:nth-of-type(3) {
    left: -50%;
    opacity: 0;
  }
  &.active li:nth-of-type(4) {
    right: -50%;
    opacity: 0;
  }

  ul {
    width: 50px;
    height: 50px;
    cursor: pointer;
  }
  ul li {
    list-style: none;
    position: absolute;
    background: $white;
    width: 100%;
    height: 10px;
    transform: translateY(-50%);
    transition: 0.3s;
  }
  ul li:nth-of-type(1) {
    top: 20%;
  }
  ul li:nth-of-type(2) {
    top: 40%;
  }
  ul li:nth-of-type(3),
  ul li:nth-of-type(4) {
    width: 50%;
    top: 62%;
  }
  ul li:nth-of-type(1),
  ul li:nth-of-type(2),
  ul li:nth-of-type(3) {
    left: 0;
  }
  ul li:nth-of-type(4) {
    right: 0;
  }
}
