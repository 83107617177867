@import './../../variables.scss';

.use_case_wrap {
  .use_case_item {
    background: #ffffff;
    box-shadow: 0px 15px 25px rgba(168, 178, 228, 0.4);
    border-radius: 10px;
    overflow: hidden;
    border-bottom: 5px solid #e82127;
    margin-bottom: 60px;

    .top_section {
      max-height: 290px;
      min-height: 150px;
      border-bottom: 3px solid #eaeaf9;

      .x2 {
        flex: 1;
      }

      .first_section {
        max-width: 45%;
      }

      .main_text {
        padding: 0 43px;
        text-align: center;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 45px;
        line-height: 55px;
        display: flex;
        align-items: center;
        text-align: center;
        text-transform: capitalize;
      }

      img {
        object-fit: cover;
        object-position: center;
        width: 100%;
        min-height: 290px;
      }
    }

    .bottom_section {
      padding: 20px 60px;

      p {
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 25px;
        display: flex;
        align-items: center;
      }

      .more_text {
        overflow: hidden;
        max-height: 0px;
        transition: max-height 0.2s, opacity 0.2s;
        opacity: 0;

        * {
          line-height: 0;
          transition: line-height 0.2s, margin 0.2s;
        }

        &.active {
          max-height: 10000000px;
          opacity: 1;

          * {
            line-height: 25px;
          }
        }
      }

      .more_text:not(.active) {
        * {
          margin: 0;
        }
      }

      .dropdown_wrap {
        @include flex-row();

        svg.arrow_icon {
          cursor: pointer;
          transition: transform 0.2s;

          &.downed {
            transform: rotate(180deg);

            &:hover:active {
              transform: rotate(180deg) scale(0.93);
            }
          }

          &:hover:active {
            transform: scale(0.93);
          }
        }
      }
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .use_case_wrap {
    .use_case_item {
      .top_section {
        max-height: 100%;
        flex-direction: column;

        .x2 {
          @include flex-row();
          max-width: 100%;
          width: 100%;

          .main_text {
            font-size: 30px;
            padding: 15px 10px;
            text-align: center;
            width: 100%;
            display: block;
          }
        }

        img {
          min-height: 150px;
        }
      }

      .bottom_section {
        padding: 10px 15px;
      }
    }
  }
}
