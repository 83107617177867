@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');
@import './variables.scss';
@import './animations.scss';

html {
  scroll-behavior: smooth;
  background: #fff;

  &.overflow_hidden {
    overflow: hidden;
  }
}

body {
  overflow-x: hidden;
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;

  * {
    box-sizing: border-box;
  }

  .content_wrap {
    max-width: 1250px;
    width: 100%;
    margin-left: 50%;
    transform: translate(-50%);
    padding: 0 15px;
  }

  main {
    min-height: 100vh;
    padding-top: $mainnav_height;
  }

  h1 {
    font-weight: 700;
    font-size: 53px;
    line-height: 65px;
    color: #000000;
    margin: 0;
    margin-bottom: 20px;

    &.main {
      margin-bottom: 50px;
      margin-top: 50px;
    }
  }

  h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #000000;
    margin: 0;
  }

  h3 {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }

  .sub_head {
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: #000000;
    margin-bottom: 30px;
  }

  .text_center {
    text-align: center;
  }

  .sub_title {
    font-weight: 700;
    font-size: 25px;
    line-height: 25px;
    color: #000000;
  }

  .sub_para {
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #000000;
  }

  .flex_box {
    @include flex-row();

    &.flex_box_column {
      flex-direction: column;
    }

    &.space_between {
      justify-content: space-between;
    }

    &.align_start {
      align-items: flex-start;
    }

    &.align_items {
      align-content: flex-start;
    }

    &.justify_start {
      justify-content: flex-start;
    }

    &.flex_wrap {
      flex-wrap: wrap;
    }

    .x2 {
      width: 50%;
    }
  }

  button,
  .button {
    font-family: 'Montserrat';
    font-style: normal;
    border: none;
    background: $blue-x1;
    border-radius: 10px;
    padding: 16px 25px;
    color: #ffffff;
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
    border: 1px solid $blue-x1;
    cursor: pointer;
    transition: transform 0.1s;

    &.secondary {
      border: 1px solid $blue-x1;
      background-color: #fff;
      color: $blue-x1;
    }

    &.colorful {
      background: linear-gradient(90deg, #3463ff 0%, #8349ff 100%);
      box-shadow: 0px 14px 48px rgba(67, 101, 213, 0.18);
      border-radius: 10px;
      padding-left: 73px;
      padding-right: 73px;
      border: none;
    }

    &:hover {
      transform: scale(1.02);

      &:active {
        transform: scale(0.99);
      }
    }
  }

  .font_700 {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }

  .font_700_17 {
    font-weight: 700;
    font-size: 17px;
    line-height: 21px;
    letter-spacing: -0.231818px;
  }

  .font_700_20 {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: capitalize;
  }

  .font_500 {
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.204545px;
  }

  .font_400 {
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: -0.3px;
  }

  .large_text {
    font-weight: 400;
    font-size: 22px;
    line-height: 27px;
    letter-spacing: -0.3px;
  }

  .line {
    display: flex;
    align-items: center;
    mix-blend-mode: normal;
    opacity: 0.1;

    &:after {
      content: '';
      flex: 1;
      margin-left: 8px;
      height: 1px;
      background: #ffffff;
      top: 10px;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  body {
    h1 {
      font-size: 26px;
      line-height: 36px;
      margin-bottom: 20px;

      &.main {
        margin: 5px 0;
        margin-bottom: 40px;
      }
    }

    h2 {
      font-size: 16px;
      line-height: 22px;
    }

    h3 {
      font-size: 17px;
    }

    button,
    .button {
      padding: 12px 14px;
      font-size: 14px;
    }
  }
}
