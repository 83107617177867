@import './../../variables.scss';

.plan_card {
  background: #ffffff;
  box-shadow: 0px 15px 25px rgba(168, 178, 228, 0.17);
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 40px;
  z-index: 1;
  border-right: 2px solid #9faffc;
  @include flex-row();
  max-width: 440px;
  width: 100%;
  margin-right: 170px;
  align-items: flex-start;
  padding-top: 40px;

  &.right_aligned {
    border-right: 0;
    margin-right: 0;
    margin-left: 170px;
    border-left: 2px solid #9faffc;
  }

  .icon_wrap {
    svg {
      margin-top: -20px;
      margin-right: 10px;
      width: 60px;
      height: 60px;
    }
  }

  .content {
    @include flex-column();
    align-items: flex-start;
    width: 100%;

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }

    .description {
      font-weight: 400;
      font-size: 15px;
      line-height: 23px;
    }
  }
}

.ecosystem_card {
  background: #ffffff;
  box-shadow: 0px 15px 25px rgba(168, 178, 228, 0.17);
  border-radius: 20px;
  padding: 30px;
  margin-bottom: 40px;
  z-index: 1;
  border-right: 2px solid #9faffc;
  @include flex-column();
  max-width: 440px;
  width: 100%;
  margin-right: 170px;
  align-items: flex-start;
  padding-top: 40px;
  border-right: 0;
  margin-right: 0;
  margin-left: 170px;
  border-left: 2px solid #9faffc;
  max-width: calc(33.33% - 60px);
  margin: 0;
  margin-bottom: 0px;
  box-shadow: 0px 15px 25px rgba(168, 178, 228, 0.17);

  .text_wrap {
    @include flex-row();
    width: 100%;
    justify-content: flex-start;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;

    img {
      margin-right: 10px;
      width: 50px;
      height: 50px;
    }
  }

  .links_wrap {
    @include flex-row();
    width: 100%;
    justify-content: space-evenly;
    margin-top: -8px;

    .link {
      @include flex-column();

      svg {
        margin-bottom: 10px;
        width: 50px;
        height: 50px;
      }
    }
  }
}
.connection_card {
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 15px 25px rgba(168, 178, 228, 0.4);
  border-radius: 10px;
  width: 100%;
  max-width: 316.25px;
  margin-bottom: 100px;
  margin-left: 18.33px;

  &.red {
    border-bottom: 2px solid #e82127;
  }
  &.blue {
    border-bottom: 2px solid #005288;
  }

  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: $black;
    border-bottom: 1px solid $grey-lite;
    padding: 30px 30px 27.5px;
  }

  .image {
    padding: 30px 30px 30px;
    height: 100%;
  }

  svg {
    height: 155px;
    width: 100%;
  }

  .center_line {
    display: flex;
    align-items: center;
    mix-blend-mode: normal;
    opacity: 0.1;

    &:after {
      content: '';
      flex: 1;
      margin-left: 8px;
      height: 1px;
      background: $grey;
      top: 10px;
    }
  }
}

.content_card_wrap {
  margin-bottom: 43px;

  .title {
    color: $black;
    margin-right: 10px;
    margin-bottom: 16px;
  }

  img {
    width: 30px;
    height: 30px;
  }

  .content_box {
    box-sizing: border-box;
    max-width: 740px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 15px 25px rgba(168, 178, 228, 0.17);
    border-radius: 20px;
    border: 3px solid #95b9f0;
    padding: 30px;

    p:last-child {
      margin-bottom: 0;
    }

    .sub_title {
      margin-bottom: 15px;
    }
  }
}

.social_card {
  box-sizing: border-box;
  width: 100%;
  max-width: 315px;
  background: #ffffff;
  box-shadow: 0px 15px 25px rgba(168, 178, 228, 0.17);
  border-radius: 10px;
  padding: 20px 20px 18px 20px;
  margin-right: 20px;
  margin-bottom: 20px;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &.violet {
    border-bottom: 2px solid $violet;

    .detail_box:first-child {
      color: $violet;
    }
  }

  &.blue {
    border-bottom: 2px solid $blue-x2;

    .detail_box:first-child {
      color: $blue-x2;
    }
  }

  &.black {
    border-bottom: 2px solid $dark-black;

    .detail_box:first-child {
      color: $dark-black;
    }
  }

  .icon {
    margin-right: 20px;
    width: 50px;
    height: 50px;
  }

  .text {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }

  .link {
    svg {
      width: 30px;
      height: 30px;
    }
  }
}

@media screen and (max-width: $breakpoint-xs) {
  .social_card {
    max-width: 100%;
    margin-right: 0;

    .text {
      font-size: 18px;
    }
  }

  .plan_card {
    margin-left: 0 !important;
    margin-right: 0;
  }

  .content_card_wrap {
    .title_wrapper {
      justify-content: center !important;
    }
  }

  .connection_card {
    margin-left: 0;
    margin-bottom: 30px;
  }
}
